import jwt_decode from 'jwt-decode';
import { message } from 'antd';
import { JWTModel } from 'typings/profile/security';
import { DEFAULT_TABLE_LIMIT } from '../constants/global';

// Generate unique id
export function generateUniqId() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function enumToMap(enumeration: any): Map<string, string | number> {
  const map = new Map<string, string | number>();
  for (const key in enumeration) {
    //TypeScript does not allow enum keys to be numeric
    if (!isNaN(Number(key))) continue;

    const val = enumeration[key] as string | number;

    //TypeScript does not allow enum value to be null or undefined
    if (val !== undefined && val !== null) map.set(key, val);
  }

  return map;
}

// Generate all path array from current location pathname (from tree)
// e.g Input Value = /system/admin/user -> Result = ['/system', '/system/admin', '/system/admin/user']
export const getDefaultOpenedKeys = (pathName: string) => {
  const pathArray = pathName.split('/').filter((el) => el);
  if (pathArray.length <= 1) return [];

  let path = '';
  const result: string[] = [];

  pathArray.forEach((e, i) => {
    if (i === 0) {
      path = `/${e}`;
    } else {
      path = `${path}/${e}`;
    }

    result.push(path);
  });

  return result;
};

export const getTablePaginationOffset = (current: number) => {
  const result = (current - 1) * DEFAULT_TABLE_LIMIT;
  return result;
};

export const sha256 = async (message: string): Promise<string> => {
  const msgUint8 = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
};

export const sortObjectKeys = (obj: { [key: string]: any }) => {
  if (!Object.keys(obj).length) {
    return obj;
  }

  return Object.keys(obj)
    .sort()
    .reduce((acc: { [key: string]: any }, key: string) => {
      if (Array.isArray(obj[key])) {
        acc[key] = obj[key].map(sortObjectKeys);
      } else if (typeof obj[key] === 'object') {
        acc[key] = sortObjectKeys(obj[key]);
      } else {
        acc[key] = obj[key];
      }
      return acc;
    }, {});
};

export function formatBooleanValue(value: unknown) {
  if (typeof value === 'boolean') {
    return value;
  } else {
    return null;
  }
}

export const scrollTop = () => window.scrollTo(0, 0);

export function accessPropertyByString<T>(
  obj: T,
  fieldString: string,
): unknown {
  const keys = fieldString.split('.');
  let value: unknown = obj;

  for (const key of keys) {
    if (Array.isArray(value)) {
      const index = parseInt(key, 10);
      if (!isNaN(index) && index >= 0 && index < value.length) {
        value = value[index];
      } else {
        value = undefined;
        break;
      }
    } else {
      value = (value as { [key: string]: unknown })[key];
    }
  }

  return value;
}

export function tryToParseJSON(value: string) {
  const result: { success: boolean; value: unknown } = { success: true, value };

  try {
    result.value = JSON.parse(value);
  } catch (err) {
    result.success = false;
  }

  return result;
}

export function decodeJWTToken(token: string): JWTModel | null {
  if (!token) {
    return null;
  }

  try {
    return jwt_decode(token) as JWTModel;
  } catch (error) {
    return null;
  }
}

export function isAccessTokenAlive(token: string) {
  const decodedToken = decodeJWTToken(token);
  return decodedToken ? decodedToken.exp * 1000 > Date.now() : false;
}

// Utility function to compare versions
const compareVersions = (frontendVersion: string, backendVersion: string) => {
  if (frontendVersion === backendVersion) {
    return false;
  }

  const [frontMajor, frontMinor, frontPatch] = frontendVersion
    .split('.')
    .map(Number);
  const [backMajor, backMinor, backPatch] = backendVersion
    .split('.')
    .map(Number);

  if (backMajor > frontMajor) {
    return true;
  }

  if (backMajor === frontMajor && backMinor > frontMinor) {
    return true;
  }

  if (
    backMajor === frontMajor &&
    backMinor === frontMinor &&
    backPatch > frontPatch
  ) {
    return true;
  }

  return false; // backend version is not greater
};

export const checkAppVersionAndRefreshIfNeeded = (
  backendVersion: string,
  tooltipMessage: string,
) => {
  const frontendVersion = process.env.REACT_APP_SWAGGER_VERSION;
  if (
    frontendVersion &&
    backendVersion &&
    compareVersions(frontendVersion || '', backendVersion)
  ) {
    message.info(tooltipMessage);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }
};
