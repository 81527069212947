import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { useField } from 'formik';
import { SelectOption } from '@core_components/Select';
import { EntityHelpers } from '../../../../../helpers/crm/entity';
import { ContactHelpers } from '../../../../../helpers/crm/contact';
import { AutocompleteOption } from '@core_components/Autocomplete';
import { RelationshipHelpers } from '../../../../../helpers/crm/relationship';
import { RelationshipTemplate } from '../../../../../typings/application/relationship-template';
import { ClientGroupMemberModel } from '../../../../../api/accountManagement/userManagementAPINew';
import {
  OnboardingEntryTypes,
  RelationshipTrackTypes,
} from '../../../../../enums/onboarding/crm';

// components
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import InfoTooltip from 'components/Tooltips/InfoTooltip';
import SectionIntro from '../../../../DesignSystem/Common/Texts/SectionIntro';
import DeleteButton from '../../../../DesignSystem/Common/Buttons/DeleteButton';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import HideIfDisabledForm from '../../../HideIfDisabledForm';
import AdditionalField, {
  AdditionalFieldFormItemModel,
} from '../../../FormComponents/AdditionalField';
import ClientGroupMembersAutocomplete, {
  ClientGroupMembersAutocompleteProps,
} from '../../../FormComponents/Autocompletes/CRM/ClientGroupMembersAutocomplete';
import RelationshipTemplatesAutocomplete, {
  RelationshipTemplatesAutocompleteProps,
} from 'components/Forms/FormComponents/Autocompletes/CRM/RelationshipTemplatesAutocomplete';
import { Col, Row } from 'antd';

export type ParentNodeOfRelationshipModel = {
  id: string | null;
  type: OnboardingEntryTypes | null;
};

export type FormValuesModel = {
  // This should be a unique id that will be used as a key for relationship card
  customId: string;

  id: string | null;
  parent: ParentNodeOfRelationshipModel;

  child: {
    id: string | null;
    type: OnboardingEntryTypes | null;
    label: string;
    autocompleteInitialValue?: AutocompleteOption<ClientGroupMemberModel>;
  };

  relationshipTemplate: {
    id: string | null;
    childRelationshipLabel: string;
    initialOption?: SelectOption<RelationshipTemplate>;
  };

  additionalFields: AdditionalFieldFormItemModel[];

  isApplicantRelationship?: boolean;
};

interface IProps {
  fieldName: string;
  activeScopeId: string;
  clientGroupId: string;
  removeCallback?: () => void;
  relationshipIndex?: number;
  clientGroupEntryType: OnboardingEntryTypes;
  relationshipFromValue?: string;
  selectedRelationshipTemplateIds: string[];
}

const RelationshipForm = memo(
  ({
    fieldName,
    activeScopeId,
    clientGroupId,
    removeCallback,
    relationshipIndex,
    clientGroupEntryType,
    relationshipFromValue,
    selectedRelationshipTemplateIds,
  }: IProps) => {
    const { t } = useTranslation('onboarding');
    const [field, , helpers] = useField<FormValuesModel>(fieldName);

    const handleChildSelect = (
      newValue: string,
      item: ClientGroupMemberModel,
    ) => {
      const label =
        item.type === OnboardingEntryTypes.Contact
          ? ContactHelpers.getFormattedContactName(
              item.contact.firstName,
              item.contact.lastName,
              item.contact.middleName,
            )
          : EntityHelpers.getEntityNameByNameType(
              item.organization?.names || [],
            );

      helpers.setValue({
        ...field.value,
        child: {
          ...field.value.child,
          id: newValue,
          type: item.type,
          label,
        },
        relationshipTemplate: {
          id: null,
          childRelationshipLabel: '',
        },
      });
    };

    const handleRelationshipTemplateSelect = (
      newValue: string,
      relationshipTemplate?: RelationshipTemplate,
    ) => {
      if (relationshipTemplate) {
        const additionalFields: AdditionalFieldFormItemModel[] =
          relationshipTemplate.additionalFields
            .filter(
              ({ relatedTo }) =>
                relatedTo === 'any' || relatedTo === field.value.parent.type,
            )
            .map((e) => ({
              name: e.name,
              type: e.type,
              value: undefined,
              relatedTo: e.relatedTo,
              options: {
                onboardingRequired: e.options.onboardingRequired,
                isForRegulatedOnly: e.options.isForRegulatedOnly,
                documentRequiresCertification:
                  e.options.documentRequiresCertification,
                selectOptions: e.options.selectOptions.length
                  ? e.options.selectOptions.map((e) => JSON.parse(e.valueJSON))
                  : [],
                formDocument: e.options.formDocument,
              },
              id: e._id,
            }));

        helpers.setValue({
          ...field.value,
          additionalFields,
          relationshipTemplate: {
            ...field.value.relationshipTemplate,
            id: newValue,
            childRelationshipLabel: RelationshipHelpers.getRelationshipName(
              {
                parentName: relationshipTemplate.parentName,
                childName: relationshipTemplate.childName,
              },
              RelationshipTrackTypes.Child,
            ),
          },
        });
      }
    };

    const renderAdditionalForm = (
      additionalFields: AdditionalFieldFormItemModel[],
    ) => {
      return additionalFields.map((ad, i) => (
        <Col span="12" key={`${ad.name}-${i}`}>
          <FormField
            disabled={ad.isRemoved}
            label={
              <DivAlignCenter>
                {ad.name}{' '}
                {ad.isRemoved && (
                  <StyledInfoTooltip
                    tooltipContent={t(
                      'node_modal.relationships.removed_additional_field_info',
                    )}
                  />
                )}
              </DivAlignCenter>
            }
            name={`${fieldName}.additionalFields.${i}.value`}
            component={AdditionalField}
            additionalProps={{
              field: {
                type: ad.type,
                options: ad.options.selectOptions,
                formDocument: ad.options.formDocument,
                documentAssociation: field.value.parent.id
                  ? { id: field.value.parent.id, type: field.value.parent.type }
                  : undefined,
              },
            }}
          />
        </Col>
      ));
    };

    const isNewRelationship = !field.value.id;

    return (
      <>
        {!!relationshipIndex && (
          <SectionIntro
            titleVariant="h5"
            title={t('node_modal.relationships.relationship_title', {
              index: relationshipIndex,
            })}
            appendToTitle={
              removeCallback && (
                <HideIfDisabledForm hideIfSubmitting>
                  <StyledDeleteButton onClick={removeCallback} />
                </HideIfDisabledForm>
              )
            }
          />
        )}

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <FormField<ClientGroupMembersAutocompleteProps>
              disabled={!isNewRelationship}
              labelCol={{ xl: 4, lg: 4, md: 5, sm: 24, xs: 24 }}
              label={t('node_modal.relationships.form_fields.child_node')}
              name={`${fieldName}.child.id`}
              component={ClientGroupMembersAutocomplete}
              additionalProps={{
                clientGroupId,
                entryType: clientGroupEntryType,
                relationshipTemplateMemberType: RelationshipTrackTypes.Child,
                excludeElementIds: field.value.parent.id
                  ? [field.value.parent.id]
                  : undefined,
                initialValue: field.value.child.autocompleteInitialValue,
                onSelect: (newValue, option) =>
                  option.model &&
                  handleChildSelect(newValue as string, option.model),
              }}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <FormField
              disabled
              label={t('node_modal.relationships.form_fields.parent_node')}
              name={`${fieldName}.parent.id`}
              component={FormInput}
              additionalProps={{ value: relationshipFromValue }}
            />
          </Col>

          <Col span={12}>
            <FormField<RelationshipTemplatesAutocompleteProps>
              disabled={
                !isNewRelationship ||
                !(field.value.parent.type && field.value.child.id)
              }
              label={t(
                'node_modal.relationships.form_fields.parent_relationship_template',
              )}
              name={`${fieldName}.relationshipTemplate.id`}
              component={RelationshipTemplatesAutocomplete}
              additionalProps={{
                relationshipScopeId: activeScopeId,
                childId: field.value.child.id as string,
                parentType: field.value.parent.type as OnboardingEntryTypes,
                onSelect: (newValue, option) =>
                  handleRelationshipTemplateSelect(
                    newValue as string,
                    option.model,
                  ),
                trackType: RelationshipTrackTypes.Parent,
                excludeElementIds: selectedRelationshipTemplateIds,
                initialValue: field.value.relationshipTemplate.initialOption,
              }}
            />
          </Col>

          <Col span={12}>
            <FormField
              disabled
              label={t('node_modal.relationships.form_fields.child_node')}
              name={`${fieldName}.child.label`}
              component={FormInput}
            />
          </Col>

          <Col span={12}>
            <FormField
              disabled
              label={t(
                'node_modal.relationships.form_fields.child_relationship_template',
              )}
              name={`${fieldName}.relationshipTemplate.childRelationshipLabel`}
              component={FormInput}
            />
          </Col>
        </Row>

        {!!field.value.additionalFields.length && (
          <>
            <SectionIntro
              title={t(
                'node_modal.relationships.additional_fields_section_title',
              )}
              titleVariant="h5"
            />
            <Row gutter={[16, 0]}>
              {renderAdditionalForm(field.value.additionalFields)}
            </Row>
          </>
        )}
      </>
    );
  },
);

const StyledDeleteButton = styled(DeleteButton)`
  margin-bottom: ${({ theme }) => theme.marginXs};
`;

const StyledInfoTooltip = styled(InfoTooltip)`
  margin-left: ${({ theme }) => theme.marginXXs};
`;

export default RelationshipForm;
